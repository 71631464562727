<template>
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                التقرير التراكمي للمعلمين من بداية هذا العام وحتى اليوم.
            </div>
            <div class="card-body">
                <div class="col-12 text-center g" v-if="loading">
                    <img :src="require('@/assets/images/loading.svg')" style="width: 100px; height: 100px" alt="">
                    <br>
                    جاري الاستعلام...
                </div>
                <div class="col-12 g" v-if="!loading">
                    <button class="btn btn-sm btn-primary" @click="print()">
                        <i class="fa fa-print"></i>
                        طباعة الجدول
                    </button>&nbsp;
                    <button class="btn btn-sm btn-success" @click="excel()">
                        <i class="fa fa-download"></i>
                        تصدير الجدول
                    </button>
                </div>
                <div class="col-12 table-responsive" v-if="!loading" id="printme">
                    <table class="table table-hover table-bordered table-sm table-striped">
                        <thead>
                            <th>
                                م
                            </th>
                            <th>
                                المعلم
                            </th>
                            <th>
                                الفصول
                            </th>
                            <th>
                                الحصص المحضرة
                            </th>
                            <th>
                                المهام
                            </th>
                            <th>
                                التقييمات
                            </th>
                            <th>
                                الخطط الاسبوعية
                            </th>
                            <th>
                                التحولات للوكيل
                            </th>
                            <th>
                                المحادثات
                            </th>
                            <th>
                                المحادثات المرسلة
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(teacher, i) in teachers" :key="teacher._id">
                                <td>
                                    {{ i+1 }}
                                </td>
                                <td>
                                    {{ teacher.name }}
                                </td>
                                <td>
                                    {{ teacher.classes }}
                                </td>
                                <td>
                                    {{ teacher.lessons }}
                                </td>
                                <td>
                                    {{ teacher.rates }}
                                </td>
                                <td>
                                    {{ teacher.tasks }}
                                </td>
                                <td>
                                    {{ teacher.weeklyplans }}
                                </td>
                                <td>
                                    {{ teacher.forwards }}
                                </td>
                                <td>
                                    {{ teacher.chats }}
                                </td>
                                <td>
                                    {{ teacher.messages }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const XLSX = require('xlsx');
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            teachers: [],
            loading: true
        }
    },
    created(){
        if(!checkPer("teachers|fullreport")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + "/user/teachers/reports/fullreport", {
            jwt: g.user.jwt,
        })
        .then(function (r) {
            g.loading = false;
            g.teachers = r.response
        }).fail(function(){g.loading = false;alert("حدث خطأ في الاتصال")})
    },
    methods: {
        excel(){
            const wb = XLSX.utils.book_new(), g = this;
        var x = [
            "م",
            "المعلم",
            "الفصول",
            "الحصص المحضرة",
            "المهام",
            "التقييمات",
            "الخطط الاسبوعية",
            "التحولات للوكيل",
            "المحادثات",
            "المحادثات المرسلة"
        ];
        const Heading = [
            x
        ];
        var xx = [];
        g.teachers.forEach(function(a, i){
            var xr = [];
            xr.push(i+1)
            xr.push(a.name)
            xr.push(a.classes)
            xr.push(a.lessons)
            xr.push(a.rates)
            xr.push(a.tasks)
            xr.push(a.weeklyplans)
            xr.push(a.forwards)
            xr.push(a.chats)
            xr.push(a.messages)
            xx.push(xr)
        })
        
        // creating sheet and adding data from 2nd row of column A.
        // leaving first row to add Heading
        const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
            
        // adding heading to the first row of the created sheet.
        // sheet already have contents from above statement.
        XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
            
        // appending sheet with a name
        XLSX.utils.book_append_sheet(wb, ws, 'Records');
        const fileContent = XLSX.writeFile(wb, "تقرير المعلمين التراكمي" + ".xlsx");
        },
        print(){
            var x = window.open(``)
            x.document.write(`
            <html dir="rtl">
            <style>
            
            table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}</style>
                <body>
                    ${$("#printme").html()}
                </body>
            </html>
            `)
            x.document.close()
            x.print()
        }
    }
}
</script>

<style>

</style>